import AbstractApiModule from '../AbstractApiModule';
import CommonModule from './mainApi/CommonModule';
import UserModule from './mainApi/UserModule';
import AuthorizationModule from './mainApi/AuthorizationModule';
import SelectCityModule from './mainApi/SelectCityModule';
import CatalogModule from './mainApi/CatalogModule';
import CatalogMarketingModule from './mainApi/CatalogMarketingModule';
import ProductModule from './mainApi/ProductModule';
import BasketModule from './mainApi/BasketModule';
import ReviewsModule from './mainApi/ReviewsModule';
import PromoCodeModule from './mainApi/PromoCodeModule';
import SubscriptionsModule from './mainApi/SubscriptionsModule';
import LocatorModule from './mainApi/LocatorModule';
import CheckoutModule from './mainApi/CheckoutModule';
import OrderModule from './mainApi/OrderModule';
import LandingsModule from './mainApi/Landings';
import QuestionsModule from './mainApi/QuestionsModule';
import ProfileConsumerModule from './mainApi/ProfileConsumerModule';
import ProfileSpecialistModule from './mainApi/ProfileSpecialistModule';
import MoneybackModule from './mainApi/MoneybackModule';
import ReceiptModule from './mainApi/ReceiptModule';
import Samples from './mainApi/Samples';
import CalculatorModule from './mainApi/CalculatorModule';
import VideoCompetitionModule from './mainApi/VideoCompetitionModule';
import HCPModule from './mainApi/HCPModule';

export default class MainApiModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.modules = {
      common            : new CommonModule({ apiClient: this.client, apiURL: this.apiURL }),
      user              : new UserModule({ apiClient: this.client, apiURL: this.apiURL }),
      authorization     : new AuthorizationModule({ apiClient: this.client, apiURL: this.apiURL }),
      selectCity        : new SelectCityModule({ apiClient: this.client, apiURL: this.apiURL }),
      catalog           : new CatalogModule({ apiClient: this.client, apiURL: this.apiURL }),
      catalogMarketing  : new CatalogMarketingModule({ apiClient: this.client, apiURL: this.apiURL }),
      product           : new ProductModule({ apiClient: this.client, apiURL: this.apiURL }),
      basket            : new BasketModule({ apiClient: this.client, apiURL: this.apiURL }),
      reviews           : new ReviewsModule({ apiClient: this.client, apiURL: this.apiURL }),
      promoCode         : new PromoCodeModule({ apiClient: this.client, apiURL: this.apiURL }),
      subscriptions     : new SubscriptionsModule({ apiClient: this.client, apiURL: this.apiURL }),
      locator           : new LocatorModule({ apiClient: this.client, apiURL: this.apiURL }),
      checkout          : new CheckoutModule({ apiClient: this.client, apiURL: this.apiURL }),
      order             : new OrderModule({ apiClient: this.client, apiURL: this.apiURL }),
      landings          : new LandingsModule({ apiClient: this.client, apiURL: this.apiURL }),
      questions         : new QuestionsModule({ apiClient: this.client, apiURL: this.apiURL }),
      profileConsumer   : new ProfileConsumerModule({ apiClient: this.client, apiURL: this.apiURL }),
      profileSpecialist : new ProfileSpecialistModule({ apiClient: this.client, apiURL: this.apiURL }),
      moneyback         : new MoneybackModule({ apiClient: this.client, apiURL: this.apiURL }),
      receipt           : new ReceiptModule({ apiClient: this.client, apiURL: this.apiURL }),
      samples           : new Samples({ apiClient: this.client, apiURL: this.apiURL }),
      calculator        : new CalculatorModule({ apiClient: this.client, apiURL: this.apiURL }),
      hcp               : new HCPModule({ apiClient: this.client, apiURL: this.apiURL }),
      videoCompetition  : new VideoCompetitionModule({ apiClient: this.client, apiURL: this.apiURL })
    };
  }
}
