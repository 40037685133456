import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getPoints         : '/v2/internal/locator/points/',
  getSuggestsPoints : '/v2/internal/locator/points/suggests/',
  getDetailPoint    : (id) => `/v2/internal/locator/points/detail/${id}/`,
  productsLocator   : (type) => `/v1/locator/products/${type}/`,

  getDepartmentsEntrySlots : (id) => `/v2/internal/common/departments/detail/id/${id}/entry_slots/`,
  createRecruitmentRequest : (id) => `/v2/internal/common/departments/detail/id/${id}/entry/`
};

export default class LocatorModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPoints, { params }).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getDetailPoint(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailPoint(id)).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getSuggestsPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSuggestsPoints, { params }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getProductsLocator(type) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.productsLocator(type)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getDepartmentsEntrySlots(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDepartmentsEntrySlots(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  createRecruitmentRequest(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.createRecruitmentRequest(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
